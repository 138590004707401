import DateTimeHelper from './DateTimeHelper';
import ConstantsHelper from './ConstantsHelper';
import AnI18NextLibHelper from './AnI18NextLibHelper';
import UtilityHelper from './UtilityHelper';

import { IControl, IDashboardRequest, IError, IHome, IOktaData, ITimestampRange } from '../types';
import {
    dashboardMetaDataActionCreator,
    fetchDashboard,
    fetchProfile,
    authenticationActionCreator,
    intersessionDataSaveActionCreator,
    fetchLearningMaterialResources,
    fetchDashboardInsulinUsage,
    fetchDashboardInsulinUsageTrend,
    fetchDashboardSummary,
    anchorAllReportsActionCreator,
    fetchInsightEventReadings,
    fetchDashboardSummaryGlucoseTrend,
    fetchInsightEventBoluses,
    fetchInsightEventModes,
    controlActionCreator,
    loadingActionCreator,
    fetchLearningMaterialOp5Progress,
    errorActionCreator,
    fetchBackEndId,
    fetchClinics,
} from '../store/home';

export default class UiHelper {
    public static GetOnOffSuffix = (isOn: boolean) => (isOn ? 'On' : 'Off');

    public static IsSiteReadyToGo = (home: IHome) =>
        (home.patient?.receivedData && home.allLoaded) || UiHelper.HaveError(home);

    public static SetLoadingSemaphore = (dispatch: any, loadingDir: number) =>
        dispatch(loadingActionCreator({ loadingDir }));

    public static SignOutArm = async (dispatch: any, signOutKeyToCheck: string) => {
        UiHelper.SetLoadingSemaphore(dispatch, 1);
        dispatch(
            authenticationActionCreator({
                authentication: {
                    oktaData: {
                        access: undefined,
                        aud: undefined,
                        isUserDefined: undefined,
                        expires: undefined,
                        id: undefined,
                        userName: undefined,
                    },
                },
            })
        );
        /* CAUTION:
            Ensure all Redux dispatched actions have completed before Okta signout has been initiated as
            these actions may trigger a component refresh while signout has not been completed.  This
            could sometimes cause the session access token to refresh itself and in effect cancel the
            signout.

            An undefined isUserDefined will reset state values mapped to the following intersession keys.
        */
        dispatch(
            intersessionDataSaveActionCreator({
                keys: [
                    ConstantsHelper.IntersessionKeys.contributingEventBeg,
                    ConstantsHelper.IntersessionKeys.contributingEventEnd,
                    ConstantsHelper.IntersessionKeys.contributingEventId,
                    ConstantsHelper.IntersessionKeys.dashboardBeg,
                    ConstantsHelper.IntersessionKeys.dashboardEnd,
                    ConstantsHelper.IntersessionKeys.insightId,
                ],
            })
        );
        // Make this the last dispatch upon completion of this Okta signout will proceed with no interruptions.
        dispatch(intersessionDataSaveActionCreator({ keys: [signOutKeyToCheck] }));
    };

    public static SetControlProps = (
        dispatch: any,
        control: Partial<IControl>,
        keys?: string[] // keys contains only those props that are to be saved intersession
    ) => {
        dispatch(controlActionCreator({ control }));

        if (keys?.length > 0) {
            dispatch(intersessionDataSaveActionCreator({ keys }));
        }
    };

    public static SaveOktaData = (dispatch: any, oktaData: IOktaData): IOktaData => {
        dispatch(
            authenticationActionCreator({
                authentication: {
                    oktaData,
                },
            })
        );

        return oktaData;
    };

    public static FetchPatient = (dispatch: any, oktaData: IOktaData) => {
        if (oktaData?.isUserDefined) {
            dispatch(fetchProfile({}));
        }
    };

    public static FetchClinics = (dispatch: any, oktaData: IOktaData) => {
        if (oktaData.isUserDefined) {
            dispatch(fetchClinics(null));
        }
    };

    public static GetInsightsDateRange = (
        dispatch: any,
        home: IHome,
        dashboardBegNew?: string,
        dashboardEndNew?: string,
        dashboardHasReferrer?: boolean
    ): ITimestampRange => {
        const payload: ITimestampRange = {};
        const beg: string = dashboardBegNew ?? home.dashboardBeg;
        const end: string = dashboardEndNew ?? home.dashboardEnd;
        const datesChanged = !(beg === home.dashboardBeg && end === home.dashboardEnd);

        if (datesChanged) {
            dispatch(dashboardMetaDataActionCreator({ dashboardBeg: beg, dashboardEnd: end, dashboardHasReferrer }));
            dispatch(
                intersessionDataSaveActionCreator({
                    keys: [
                        ConstantsHelper.IntersessionKeys.dashboardBeg,
                        ConstantsHelper.IntersessionKeys.dashboardEnd,
                        ConstantsHelper.IntersessionKeys.dashboardHasReferrer,
                    ],
                })
            );
        }

        if (datesChanged || !UiHelper.IsCurrentInsightLoaded(home) || dashboardHasReferrer) {
            payload.beg = beg;
            payload.end = end;
        }

        return payload;
    };

    public static HaveError = (home: IHome, warningIsError = false): boolean => {
        const errorObj = Object.entries(home.errors ?? {}).find(
            (entry) =>
                (warningIsError && entry[1].level === ConstantsHelper.ErrorLevels.warning) ||
                entry[1].level === ConstantsHelper.ErrorLevels.error
        );

        return errorObj ? true : false;
    };

    public static ClearErrors = (dispatch: any) => {
        dispatch(errorActionCreator({ key: undefined, error: undefined }));
    };

    public static CanFetchData = (home: IHome): boolean => {
        if (
            home?.control?.pauseItEnabled &&
            !(home.patient?.eulaAccepted && home.patient?.confidentialityAgreementAccepted)
        ) {
            return false;
        }

        return true;
    };

    public static FetchBackEndId = (dispatch: any, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchBackEndId({}));
        }
    };

    public static FetchDashboard = (dispatch: any, beg: string, end: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [beg, end]);

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchDashboard({ beg, end, limit: 10000, offset: 0 } as IDashboardRequest));
        }
    };

    public static FetchLearningMaterialOp5Progress = (dispatch: any, beg: string, end: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchLearningMaterialOp5Progress({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchLearningMaterialResources = (dispatch: any, beg: string, end: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchLearningMaterialResources({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardInsulinUsage = (dispatch: any, beg: string, end: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage, [
            beg,
            end,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchDashboardInsulinUsage({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardInsulinUsageTrend = (dispatch: any, beg: string, end: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchDashboardInsulinUsageTrend({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardSummary = (dispatch: any, beg: string, end: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary, [
            beg,
            end,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchDashboardSummary({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchDashboardSummaryGlucoseTrend = (dispatch: any, beg: string, end: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
            [beg, end]
        );

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchDashboardSummaryGlucoseTrend({ beg, end } as IDashboardRequest));
        }
    };

    public static FetchInsightEventReadings = (dispatch: any, insightId: string, eventId: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings, [
            insightId,
            eventId,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchInsightEventReadings({ insightId, eventId }));
        }
    };

    public static FetchInsightEventBoluses = (dispatch: any, insightId: string, eventId: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses, [
            insightId,
            eventId,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchInsightEventBoluses({ insightId, eventId }));
        }
    };

    public static FetchInsightEventModes = (dispatch: any, insightId: string, eventId: string, home: IHome) => {
        const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes, [
            insightId,
            eventId,
        ]);

        if (UtilityHelper.IsHttpCallAllowed(home?.control, callKey) && UiHelper.CanFetchData(home)) {
            dispatch(fetchInsightEventModes({ insightId, eventId }));
        }
    };

    public static setDashboardConfig = (dispatch: any, beg: string, end: string, anchorAllReports: string) => {
        dispatch(
            dashboardMetaDataActionCreator({
                dashboardBeg: beg,
                dashboardEnd: end,
                dashboardHasReferrer: true,
            })
        );
        dispatch(anchorAllReportsActionCreator({ anchorAllReports }));
    };

    public static CreateErrorMessage = (error: IError): IError => ({
        ...error,
        timeStamp: DateTimeHelper.FormatTimeStamp(undefined, undefined, true, false, true),
    });

    public static CopyToClipBoard = (payload: string) => {
        if (navigator.clipboard?.writeText) {
            navigator.clipboard.writeText(payload);
        }
    };

    public static IsCurrentInsightLoaded = (home: IHome) => home.currentDashboard?.insightIds?.length > 0;

    public static GetBannerSpacingClasses = (
        isProd: boolean,
        isSupported: boolean,
        classNoBanners: string,
        classOneBanner: string,
        classTwoBanners: string
    ) => {
        if (!isProd && !isSupported) return classTwoBanners;
        if (!isProd || !isSupported) return classOneBanner;

        return classNoBanners;
    };

    public static Translate = (home: IHome, key: string, subs?: any) =>
        AnI18NextLibHelper.Translate(home?.anI18Nextlib, key, subs);
}
