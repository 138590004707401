/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Patient glucose device bolus delivery information
 */
export interface Clinic { 
    /**
     * The ID of the assigned clinic
     */
    clinicId: string;
    /**
     * Assigned Clinic name
     */
    clinicName: string;
    /**
     * Assigned Clinic address
     */
    clinicAddress: string;
    /**
     * UI display name
     */
    patientClinicAssociationStatus: Clinic.PatientClinicAssociationStatusEnum;
    /**
     * UUIDv4 id of the associated invitation
     */
    invitationId?: string;
}
export namespace Clinic {
    export type PatientClinicAssociationStatusEnum = 'approved' | 'denied' | 'expired' | 'invited' | 'pending' | 'rejected';
    export const PatientClinicAssociationStatusEnum = {
        Approved: 'approved' as PatientClinicAssociationStatusEnum,
        Denied: 'denied' as PatientClinicAssociationStatusEnum,
        Expired: 'expired' as PatientClinicAssociationStatusEnum,
        Invited: 'invited' as PatientClinicAssociationStatusEnum,
        Pending: 'pending' as PatientClinicAssociationStatusEnum,
        Rejected: 'rejected' as PatientClinicAssociationStatusEnum
    };
}