import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { globalHistory } from '@reach/router';

import { IHome, IPatient } from 'src/types';

import SystemHelper from './SystemHelper';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: SystemHelper?.GetRuntimeConfig('REACT_APP_AZURE_APP_INSIGHTS_KEY') ?? 'Dummy Key For Jest',
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory },
        },
        disableCookiesUsage: true,
    },
});
let patientId: string | null = null;
let patient: IPatient | null = null;
let signInUrl: string | null = null;

const handlePatientChanges = (newPatient: IPatient) => {
    patient = newPatient;
};

const handleSignInUrlChange = (url: string) => {
    signInUrl = url;
};

const handlePatientIdChanges = (newPatient: IPatient) => {
    const newPatientId = newPatient.id ?? null;

    if (patientId === newPatientId) {
        return;
    }

    if (newPatientId !== null) {
        appInsights.setAuthenticatedUserContext(newPatientId);
    } else {
        appInsights.clearAuthenticatedUserContext();
    }

    patientId = newPatientId;
};

export const handleApplicationInsightsConfigurationUpdateOnStoreChange = (home: IHome) => {
    handlePatientChanges(home.patient);
    handlePatientIdChanges(home.patient);
    handleSignInUrlChange(home.signInUrl);
};

appInsights.addTelemetryInitializer((envelope) => {
    envelope.data = envelope.data ?? {};

    const pdmClass = patient?.deviceClass;
    const userType = patient?.userType;
    const clinicId = patient?.clinicId;
    const customerId = patient?.customerId;

    envelope.data = {
        ...envelope.data,
        ...(pdmClass && { pdmClass }),
        ...(clinicId && { clinicId }),
        ...(customerId && { customerId }),
        ...(userType && { userType }),
        ...(signInUrl && { signInUrl }),
    };
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };

export default (Component: React.ComponentType) => withAITracking(reactPlugin, Component);

export const sendAzureEvent = (eventName: string, properties?: ICustomProperties) =>
    appInsights.trackEvent({ name: eventName }, properties);
