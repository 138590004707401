import React, { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

import { IState } from '../types';
import styleGeneral from '../styles/general.module.scss';
import styleSettings from '../styles/settings.module.scss';
import ClinicDetails from '../components/settings/ClinicDetails';
import UiHelper from '../helpers/UiHelper';
import SystemHelper from '../helpers/SystemHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import { homeInitialState } from '../store/home';

function Settings() {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );

    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment translate={translate}>
            <div className={styleGeneral.body}>
                <div className={styleSettings.settings}>
                    <div className={styleSettings.heading}>{translate('settings.title')}</div>
                    <div className={styleSettings.body}>
                        <div className={styleSettings.section}>
                            <div className={styleSettings.title}>{translate('settings.myClinics')}</div>
                            <p className={styleSettings.description}>{translate('settings.description')}</p>
                            <p className={styleSettings.manageClinics}>
                                <Trans
                                    i18nKey="settings.manageClinics"
                                    components={{
                                        Link: (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                                            <a
                                                href={SystemHelper.GetRuntimeConfig(
                                                    'REACT_APP_PODDER_CENTRAL_CLINICS_LINK'
                                                )}
                                                className={clsx(
                                                    styleGeneral.link,
                                                    styleGeneral.link_underline,
                                                    styleGeneral.link_fontWeightSemiBold
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        ),
                                    }}
                                />
                            </p>
                        </div>

                        {home.clinics.length === 0 && (
                            <div className={styleSettings.section}>
                                <p className={styleSettings.noClinics}>{translate('settings.noClinics')}</p>
                            </div>
                        )}

                        {home.clinics.map((clinic, index) => {
                            const isPrescribingClinic = index === 0;
                            return (
                                <div
                                    key={uuidv4()}
                                    className={styleSettings.section}
                                    data-testid={`clinic-${clinic.clinicId}`}
                                >
                                    <ClinicDetails
                                        clinicDetails={clinic}
                                        translate={translate}
                                        isPrescribingClinic={isPrescribingClinic}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default Settings;
