import React from 'react';
import Chip from '@mui/material/Chip';

import { Clinic } from '../../model/clinic';
import { ITranslator } from '../../types';
import invitedIcon from '../../assets/images/icon-pending-blue.svg';
import pendingIcon from '../../assets/images/icon-pending-orange.svg';

interface IPatientClinicAssociationStatusChipProps {
    patientClinicAssociationStatus: Clinic.PatientClinicAssociationStatusEnum;
    translate: ITranslator;
}

function PatientClinicAssociationStatusChip({
    patientClinicAssociationStatus,
    translate,
}: Readonly<IPatientClinicAssociationStatusChipProps>) {
    const isClinicApproved = patientClinicAssociationStatus === Clinic.PatientClinicAssociationStatusEnum.Approved;
    const isClinicPending = patientClinicAssociationStatus === Clinic.PatientClinicAssociationStatusEnum.Pending;

    const icon = isClinicPending ? pendingIcon : invitedIcon;
    const label = isClinicPending ? translate('settings.pendingApproval') : translate('settings.invited');
    const color = isClinicPending ? 'warning' : 'info';

    if (isClinicApproved) return null;

    return <Chip icon={<img src={icon} alt="" />} label={label} color={color} className="Chip" />;
}

export default PatientClinicAssociationStatusChip;
