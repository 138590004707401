import React, { ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { Patient } from 'src/model/patient';
import ConstantsHelper from 'src/helpers/ConstantsHelper';

import ContentWrapperEnrollmentPending from './ContentWrapperEnrollmentPending';
import ContentWrapperEnrollmentRevoked from './ContentWrapperEnrollmentRevoked';
import ContentWrapperSmsNumber from './ContentWrapperSmsNumber';
import ContentWrapperNotFound from './ContentWrapperNotFound';
import ContentWrapperDisclaimer from './ContentWrapperDisclaimer';
import ContentWrapperWelcome from './ContentWrapperWelcome';
import ContentWrapperEulaAndConfAgr from './ContentWrapperEulaAndConfAgr';

import { IControl, IError, IHome, IObjectHash, IPatient, ITranslator } from '../../../types';
import UiHelper from '../../../helpers/UiHelper';
import {
    updateProfile,
    saveAndConfirmSmsNumber,
    pollProfileActionCreator,
    isMenuVisibleActionCreator,
} from '../../../store/home';
import styleGeneral from '../../../styles/general.module.scss';
import SystemHelper from '../../../helpers/SystemHelper';

const pickComponent = ({
    showError,
    showRevoked,
    showPending,
    showWelcome,
    showDisclaimer,
    showEulaAndConfAgr,
    showVerifySmsNumber,
    home,
    clearErrorCb,
    podderCentralCb,
    welcomeSeenCb,
    disclaimerSeenCb,
    eulaAndConfAgrCb,
    verifySmsNumberCb,
    confirmedSmsNumberCb,
    children,
    translate,
}: {
    showError: boolean;
    showRevoked: boolean;
    showPending: boolean;
    showWelcome: boolean;
    showDisclaimer: boolean;
    showEulaAndConfAgr: boolean;
    showVerifySmsNumber: boolean;
    home: IHome;
    clearErrorCb: any;
    podderCentralCb: any;
    welcomeSeenCb: any;
    disclaimerSeenCb: any;
    eulaAndConfAgrCb: any;
    verifySmsNumberCb: any;
    confirmedSmsNumberCb: any;
    children: any;
    translate: ITranslator;
}) => {
    if (home?.control?.reloadInProgress) {
        return null;
    } else if (showError) {
        return <ContentWrapperNotFound home={home} clearErrorCb={clearErrorCb} translate={translate} />;
    } else if (showEulaAndConfAgr) {
        return <ContentWrapperEulaAndConfAgr home={home} closeCb={eulaAndConfAgrCb} translate={translate} />;
    } else if (showVerifySmsNumber) {
        return (
            <ContentWrapperSmsNumber
                home={home}
                verifyCb={verifySmsNumberCb}
                confirmedCb={confirmedSmsNumberCb}
                translate={translate}
            />
        );
    } else if (showRevoked) {
        return <ContentWrapperEnrollmentRevoked podderCentralCb={podderCentralCb} translate={translate} />;
    } else if (showPending) {
        return <ContentWrapperEnrollmentPending podderCentralCb={podderCentralCb} translate={translate} />;
    } else if (showWelcome) {
        return <ContentWrapperWelcome home={home} closeCb={welcomeSeenCb} translate={translate} />;
    } else {
        return (
            <>
                {children}
                {showDisclaimer && (
                    <ContentWrapperDisclaimer home={home} closeCb={disclaimerSeenCb} translate={translate} />
                )}
            </>
        );
    }
};

function ContentWrapperAll({
    home,
    dispatch,
    showIfInvalidEnrollment,
    children,
    translate,
}: {
    home: IHome;
    dispatch: any;
    showIfInvalidEnrollment: boolean;
    children?: ReactNode;
    translate: ITranslator;
}) {
    const control: IControl = home.control ?? ({} as IControl);
    const patient: IPatient = home.patient ?? ({} as IPatient);
    const error: IObjectHash<IError> = home.errors ?? ({} as IObjectHash<IError>);
    const podderCentralCb = () =>
        window.location.assign(SystemHelper?.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK'));
    const clearErrorCb = () => SystemHelper.DismissError(dispatch);
    const disclaimerSeenCb = () => dispatch(updateProfile({ disclaimerSeen: true }));
    const eulaAndConfAgrCb = () =>
        dispatch(updateProfile({ eulaAccepted: true, confidentialityAgreementAccepted: true }));
    const welcomeSeenCb = () => {
        dispatch(updateProfile({ welcomeSeen: true }));

        return <Redirect to="/" />;
    };
    const verifySmsNumberCb = (smsNumber: string) => {
        dispatch(saveAndConfirmSmsNumber({ smsNumber }));
        dispatch(pollProfileActionCreator({ active: true }));
        return <Redirect to="/" />;
    };

    const confirmedSmsNumberCb = () => {
        dispatch(pollProfileActionCreator({ active: false }));
        return <Redirect to="/" />;
    };

    const redirectToHcp = () => {
        const hcpAppUrl = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.hcpAppUrl.urlKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.settingsKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.runtimeKey,
            false,
            true
        );

        window.location.replace(hcpAppUrl);
    };

    const oktaAuthState = useOktaAuth()?.authState;
    const pauseItEnabled = home.control.pauseItEnabled;
    const oktaAccessToken = oktaAuthState.accessToken;

    if (oktaAccessToken && SystemHelper.IsHcpUserSession(oktaAccessToken, pauseItEnabled)) {
        redirectToHcp();
    }

    useEffect(() => {
        if (!patient.isEnrolled) {
            const oktaData = SystemHelper.ParseOktaData(oktaAuthState, pauseItEnabled);

            UiHelper.FetchPatient(dispatch, UiHelper.SaveOktaData(dispatch, oktaData));
            UiHelper.FetchClinics(dispatch, UiHelper.SaveOktaData(dispatch, oktaData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.isEnrolled]);

    const showError = UiHelper.HaveError(home) && !error.silent;
    const showRevoked = patient.isUnenrolled && !showIfInvalidEnrollment;
    const showPending = !patient.isUnenrolled && !patient.isEnrolled && !showIfInvalidEnrollment;
    const showWelcomeDisclaimer = !patient.isUnenrolled && patient.isEnrolled && !showIfInvalidEnrollment;
    const showWelcome = !patient.welcomeSeen && showWelcomeDisclaimer;
    const showDisclaimer = !patient.disclaimerSeen && patient.welcomeSeen && showWelcomeDisclaimer;
    const showEulaAndConfAgr =
        control.pauseItEnabled && !(patient.eulaAccepted && patient.confidentialityAgreementAccepted);
    /**
     * TODO: showVerifySmsNumber should not have to care about home.isPolling, but needs to in order for the
     * confirmedSmsNumberCb function to be called upon rerender of the ContentWrapperConfirmSmsNumber. Should refactor
     * how together the App's setInterval and ContentWrapperSmsNumber are working together.
     */
    const showVerifySmsNumber =
        control.pauseItEnabled &&
        (home.isPolling || !patient.smsNumberStatus) &&
        patient.eulaAccepted &&
        patient.confidentialityAgreementAccepted &&
        patient.deviceClass === Patient.DeviceClassEnum.Omnipod5;

    const newIsMenuVisible =
        !showError && !showVerifySmsNumber && !showEulaAndConfAgr && !showWelcome && !showDisclaimer;
    useEffect(() => {
        dispatch(isMenuVisibleActionCreator({ isMenuVisible: newIsMenuVisible }));
    }, [dispatch, newIsMenuVisible]);

    return (
        <div id="content-wrapper-all" className={styleGeneral.contentWrapperAll}>
            {UiHelper.IsSiteReadyToGo(home) ? (
                pickComponent({
                    showError,
                    showRevoked,
                    showPending,
                    showWelcome,
                    showDisclaimer,
                    showEulaAndConfAgr,
                    showVerifySmsNumber,
                    home,
                    clearErrorCb,
                    podderCentralCb,
                    welcomeSeenCb,
                    disclaimerSeenCb,
                    eulaAndConfAgrCb,
                    verifySmsNumberCb,
                    confirmedSmsNumberCb,
                    children,
                    translate,
                })
            ) : (
                <div className={styleGeneral.main} data-testid="content_wrapper_all">
                    <div className={clsx(styleGeneral.body, styleGeneral.whiteBackground)} />
                </div>
            )}
        </div>
    );
}

export default ContentWrapperAll;
