import React from 'react';
import Chip from '@mui/material/Chip';

import PatientClinicAssociationStatusChip from './PatientClinicAssociationStatusChip';

import { Clinic } from '../../model/clinic';
import { ITranslator } from '../../types';
import clinicIcon from '../../assets/images/icon-clinic.svg';
import styleSettings from '../../styles/settings.module.scss';

interface IClinicDetailsProps {
    clinicDetails: Clinic;
    isPrescribingClinic: boolean;
    translate: ITranslator;
}

function ClinicDetails({ clinicDetails, translate, isPrescribingClinic }: Readonly<IClinicDetailsProps>) {
    return (
        <div>
            {isPrescribingClinic && (
                <Chip label={translate('settings.prescribingClinic')} color="primary" className="Chip" />
            )}

            <div className={styleSettings.clinicDetails}>
                <div className={styleSettings.iconContainer}>
                    <img src={clinicIcon} alt={translate('clinic')} />
                </div>

                <div>
                    <div className={styleSettings.title}>{clinicDetails.clinicName}</div>

                    <div className={styleSettings.description}>{clinicDetails.clinicAddress}</div>

                    <PatientClinicAssociationStatusChip
                        patientClinicAssociationStatus={clinicDetails.patientClinicAssociationStatus}
                        translate={translate}
                    />
                </div>
            </div>
        </div>
    );
}

export default ClinicDetails;
