import React, { useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';

import SystemHelper from '../helpers/SystemHelper';
import { anchorEventActionCreator, dashboardMetaDataActionCreator } from '../store/home/home.slice';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import { IState } from '../types';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import { homeInitialState } from '../store/home';
import { sendAzureEvent } from '../helpers/appInsights';
import DateTimeHelper from '../helpers/DateTimeHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import BtnPrimary from '../components/common/btnPrimary';
import LoyaltyInsulinUsageFactors from '../components/dashboard/loyalty/loyaltyInsulinUsageFactors';

function LoyaltyCongratsInsights(props: any) {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    const patient = home.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer } = props?.match?.params ?? {};
    /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );
    const cbLearnMoreClickFn = () => {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_LEARN_MORE, {
            weekOf: home?.dashboardBeg,
        });

        window.open(
            home?.currentLearningMaterialResource?.data?.linkToMoreResources,
            '__OP5_LEARN_MORE__',
            'noopener,noreferrer'
        );
    };
    const cbResourcesClickFn = () => {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_RESOURCES, {
            weekOf: home?.dashboardBeg,
        });

        window.open(
            SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LINK'),
            '__OP5_RESOURCES__',
            'noopener,noreferrer'
        );
    };
    const buttonCaption = home?.currentLearningMaterialResource?.data?.buttonText;

    useEffect(() => {
        if (
            !(
                home.dashboardBeg === dashboardBeg &&
                home.dashboardEnd === dashboardEnd &&
                home.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    const clearAnchorEvent = () => dispatch(anchorEventActionCreator({ anchorEvent: undefined }));
    const [scrollActive1, setScrollActive1] = useState(false);

    useLayoutEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'InsightDetails()', 'Mounted');
        sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_OP5_DETAILS, {
            weekOf: home?.dashboardBeg,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (patient.receivedData && !(home.dashboardEnd && home?.currentLearningMaterialResource?.data?.weekNumber)) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, home);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchLearningMaterialOp5Progress(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, home);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        patient.receivedData,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    useEffect(() => {
        if (home.anchorEvent && home?.currentLearningMaterialResource?.data?.weekNumber) {
            NavigationHelper.ScrollIntoView(home.anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.anchorEvent, home?.currentLearningMaterialResource?.data?.weekNumber]);

    // TODO:  Add tests for this effect under tech debt work
    useEffect(() => {
        if (
            !UtilityHelper.IsNull(home.dashboardBeg) &&
            !UtilityHelper.IsNull(home.dashboardEnd) &&
            (home.dashboardEnd !== home.currentLearningMaterialResource.id ||
                DateTimeHelper.GetIsoNow() > home.currentLearningMaterialResource.expires)
        ) {
            UiHelper.FetchLearningMaterialResources(dispatch, home.dashboardBeg, home.dashboardEnd, home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        home.dashboardBeg,
        home.dashboardEnd,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment={false} translate={translate}>
            {home?.currentLearningMaterialResource?.data?.weekNumber && (
                <div
                    data-testid="loyalty-congrats-container"
                    className={styleGeneral.body}
                    onScroll={(event) => {
                        handleScroll(event);
                    }}
                >
                    <div className={styleGeneral.stickToTopBack}>
                        <BtnBack
                            url={NavigationHelper.GenUrlDashboard(
                                home.dashboardBeg,
                                home.dashboardEnd,
                                home.dashboardHasReferrer
                            )}
                        />
                    </div>
                    <div
                        className={clsx(styleGuide.report, styleGuide.solidBgWhite, styleGeneral.stickToTopLevel2)}
                        data-testid="loyalty-congrats"
                    >
                        <div
                            className={clsx(
                                styleGuide.header,
                                styleGuide.solidBgWhite,
                                styleGeneral.dashboardZoomInScroll1OffScroll2Off
                            )}
                        >
                            <div className={clsx(styleGuide.loyaltyTitle, scrollActive1 && styleGeneral.inScroll2)}>
                                {translate('loyalty.congrats.weekTitle', {
                                    weekNumber: home?.currentLearningMaterialResource?.data?.weekNumber,
                                })}
                            </div>
                            <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                                {home?.dashboardEnd && (
                                    <div
                                        className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}
                                        data-testid="loyalty-congrats-dashboardEnd"
                                    >
                                        {DateTimeHelper.FormatDateRange(home.dashboardEnd)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styleGuide.solidBg} data-testid="loyalty-congrats-frame">
                        <div className={clsx(styleGuide.insightsFullWidth, styleGeneral.dashboardSummary)}>
                            <div className={styleGuide.InsightCard}>
                                <div className={styleGuide.whiteBackground}>
                                    <div className={styleGuide.header}>
                                        <div
                                            className={clsx(
                                                styleGeneral.table,
                                                styleGuide.topGutter,
                                                styleGuide.linkCursor
                                            )}
                                        >
                                            <Tooltip title={buttonCaption}>
                                                <img
                                                    src={home?.currentLearningMaterialResource?.data?.featuredImage}
                                                    className={clsx(styleGuide.blockWidth)}
                                                    alt={translate('loyalty.congrats.therapy.imgText')}
                                                    onClick={cbLearnMoreClickFn}
                                                />
                                            </Tooltip>
                                        </div>
                                        <div className={styleGuide.loyaltyHeader}>
                                            <div className={styleGuide.titleAlt}>
                                                {home?.currentLearningMaterialResource?.data?.title}
                                            </div>
                                        </div>
                                        <div className={styleGuide.loyaltyBody}>
                                            <div className={styleGuide.text}>
                                                {home?.currentLearningMaterialResource?.data?.body}
                                            </div>
                                        </div>
                                    </div>
                                    <BtnPrimary caption={buttonCaption} cbClickFn={cbLearnMoreClickFn} />
                                    <div className={styleGuide.header}>
                                        <div className={clsx(styleGuide.lineAlt2)}></div>
                                        <div className={styleGuide.loyaltyFooter}>
                                            <div className={styleGuide.textAlt}>
                                                {translate('loyalty.congrats.therapy.read')}
                                                <div className={clsx(styleGeneral.link)} onClick={cbResourcesClickFn}>
                                                    {translate('loyalty.congrats.therapy.resourcesA')}
                                                    <sup>&#174;</sup>&nbsp;
                                                    {translate('loyalty.congrats.therapy.resourcesB')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoyaltyInsulinUsageFactors home={home} translate={translate} warningsOnly />
                </div>
            )}
        </ContentWrapperAll>
    );
}

export default LoyaltyCongratsInsights;
